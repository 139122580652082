import { Component, Prop, Vue, Watch } from 'vue-property-decorator';
import { Logger } from 'fsts';
import { namespace } from 'vuex-class';
import UserBillingInfoView from './user-billing-info/user-billing-info.vue';
import UserSubscriptionsInfoView from './user-subscriptions-info/user-subscriptions-info.vue';
import UserStorageSpaceInfoView from './user-storage-space-info/user-storage-space-info.vue';
import { Route } from 'vue-router';
import store from '@/shared/store';

const name = 'subscriptions-settings-view';
const logger = new Logger(name);
const userBillingInfoModule = namespace('userBillingInfo');

@Component({ name: name, components: { UserBillingInfoView, UserSubscriptionsInfoView, UserStorageSpaceInfoView } })
export default class SubscriptionsSettingsView extends Vue {
  @userBillingInfoModule.Getter('getUserBillingInfo')
  private userBillingInfo!: any;

  /**
   * Load the billing info before rendering component.
   */
  async beforeRouteEnter(to: Route, from: Route, next: any) {
    logger.log('beforeRouteEnter');
    await SubscriptionsSettingsView.loadFormData();
    next((vm: any) => {});
  }

  /**
   * Loads all required form data in parallel.
   */
  private static async loadFormData() {
    await Promise.all([
      store.dispatch('organization/getOrganization', store.getters['organization/getOrganization'].id),
      store.dispatch('userBillingInfo/getUserBillingInfo'),
    ]);
  }
}
