import { render, staticRenderFns } from "./subscriptions.html?vue&type=template&id=82d26f14&scoped=true&external"
import script from "./subscriptions.ts?vue&type=script&lang=ts&external"
export * from "./subscriptions.ts?vue&type=script&lang=ts&external"
import style0 from "./subscriptions.scss?vue&type=style&index=0&id=82d26f14&prod&scoped=true&lang=scss&external"


/* normalize component */
import normalizer from "!../../../../node_modules/@vue/cli-service/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "82d26f14",
  null
  
)

/* custom blocks */
import block0 from "./subscriptions.json?vue&type=custom&index=0&blockType=i18n&issuerPath=%2Fusr%2Fsrc%2Fapp%2Fsrc%2Fviews%2Fsettings%2Fsubscriptions%2Fsubscriptions.vue&external"
if (typeof block0 === 'function') block0(component)

export default component.exports