import { Component, Prop, Vue, Watch } from 'vue-property-decorator';
import { Logger } from 'fsts';
import { namespace } from 'vuex-class';
import userBillingInfo, { UserBillingInfo } from '@/shared/model/userBillingInfo';
import UserBillingInfoDialog from './user-billing-info-edit/user-billing-info-edit.vue';

const name = 'user-billing-info-view';
const logger = new Logger(name);
const userBillingInfoModule = namespace('userBillingInfo');

@Component({ name: name, components: { UserBillingInfoDialog } })
export default class UserBillingInfoView extends Vue {
  @userBillingInfoModule.Getter('getUserBillingInfo')
  private userBillingInfo!: any;
  @userBillingInfoModule.Action('getUserBillingInfo')
  private actionGetUserBillingInfo!: any;
  @userBillingInfoModule.Action('updateUserBillingInfo')
  private actionUpdateUserBillingInfo!: any;

  userBillingInfoEditDialog = {
    show: false,
    model: { ...userBillingInfo.parse({}) },
  };
  async userBillingInfoEditDialogOnClose(userBillingInfo: UserBillingInfo) {
    this.userBillingInfoEditDialog.show = false;
  }
  async userBillingInfoEditDialogOnUpdate(userBillingInfo: UserBillingInfo) {
    this.userBillingInfoEditDialog.show = false;
    this.actionUpdateUserBillingInfo(userBillingInfo).then(({ id }: any) => {
      this.actionGetUserBillingInfo();
    });
  }
  onEdit(userBillingInfo: UserBillingInfo) {
    this.userBillingInfoEditDialog.model = { ...userBillingInfo };
    this.userBillingInfoEditDialog.show = true;
  }
}
