import { Component, Prop, Vue, Watch } from 'vue-property-decorator';
import { Logger } from 'fsts';
import { namespace } from 'vuex-class';
import userBillingInfo, { UserBillingInfo } from '@/shared/model/userBillingInfo';
import UserStorageSpaceInfoDialog from './user-storage-space-info-edit/user-storage-space-info-edit.vue';
import { Organisation } from '@/shared/model/organisation';
import { OdataItems } from '@/shared/model/OdataItems';

const name = 'user-subscriptions-info-view';
const logger = new Logger(name);
const userBillingInfoModule = namespace('userBillingInfo');
const organizationModule = namespace('organization');

@Component({ name: name, components: { UserStorageSpaceInfoDialog } })
export default class UserSubscriptionsInfoView extends Vue {
  @userBillingInfoModule.Getter('getUserBillingInfo')
  private userBillingInfo!: UserBillingInfo;
  @userBillingInfoModule.Action('getUserBillingInfo')
  private actionGetUserBillingInfo!: any;
  @userBillingInfoModule.Action('updateUserBillingInfo')
  private actionUpdateUserBillingInfo!: any;
  @organizationModule.Getter('getOrganization')
  private organisation!: Organisation;
  @organizationModule.Action('getOrganization')
  private getOrganisation!: any;
  @organizationModule.Getter('getOrganizations')
  private organizations!: OdataItems<Organisation>;

  userBillingInfoEditDialog = {
    show: false,
    model: { ...userBillingInfo.parse({}) },
  };
  async userBillingInfoEditDialogOnClose(userBillingInfo: UserBillingInfo) {
    this.userBillingInfoEditDialog.show = false;
  }
  async userBillingInfoEditDialogOnUpdate(userBillingInfo: UserBillingInfo) {
    this.userBillingInfoEditDialog.show = false;
    this.actionUpdateUserBillingInfo(userBillingInfo).then(({ id }: any) => {
      this.actionGetUserBillingInfo();
      this.getOrganisation(this.organisation.id);
    });
  }
  onClickAdd() {
    this.userBillingInfoEditDialog.model = { ...this.userBillingInfo };
    this.userBillingInfoEditDialog.show = true;
  }
  get fileSizePercent() {
    const fileSizePercent = ((+this.fileSize * 100) / this.fileSizeMaxMB).toFixed(2);
    return Number.isNaN(fileSizePercent) ? 0 : fileSizePercent;
  }
  get fileSize() {
    let summOrganizationsFileSize = 0;
    this.organizations.items.forEach((item: any) => {
      if (item.organisationId == item.owner.mainOrganisationId) summOrganizationsFileSize += item.totalFileSizeForMain;
    });
    //let summOrganizationsFileSize =
    //  this.userBillingInfo.fileSize.length > 0 ? this.userBillingInfo.fileSize.reduce((p, c) => p + c) : 0;
    const fileSize = (summOrganizationsFileSize / 1024 / 1024).toFixed(2);
    return Number.isNaN(fileSize) ? 0 : fileSize;
  }

  get fileSizeInGB() {
    const fileSize = Number(this.fileSize);
    const result = (fileSize / 1024).toFixed(2);
    return result;
  }

  get fileSizeMax() {
    //calc disc sizes
    return (
      this.userBillingInfo.addonHddPackageCount * this.userBillingInfo.addonHddPackageVolume +
      this.userBillingInfo.mainHddPackageVolume
    );
  }

  get fileSizeMaxMB() {
    //convert to MB
    return this.fileSizeMax * 1000;
  }
}
