import { Component, Prop, Ref, Vue, Watch } from 'vue-property-decorator';
import { Logger } from 'fsts';
import { namespace } from 'vuex-class';
import userBillingInfo, { UserBillingInfo } from '@/shared/model/userBillingInfo';
import { Organisation } from '@/shared/model/organisation';
import { OdataItems } from '@/shared/model/OdataItems';
import IBANUtils from '@/shared/utils/ibanUtils';

const name = 'user-storage-space-info-dialog';
const logger = new Logger(name);
const userBillingInfoModule = namespace('userBillingInfo');
const organizationModule = namespace('organization');

@Component({ name: name })
export default class UserStorageSpaceInfoDialog extends Vue {
  @Ref('user-storage-space-info-form')
  private refUserStorageSpaceInfoForm!: any;
  @organizationModule.Getter('getOrganization')
  private organisation!: Organisation;
  @Prop({ default: false })
  private dialog!: boolean;
  @userBillingInfoModule.Getter('getUserBillingInfo')
  private userBillingInfo!: any;
  addonHddPackange = { id: 'HddPackage1', text: 'Speichererweiterung um 200GB für € 39,90' };
  private storageSpaces = [{ id: 'HddPackage1', text: 'Speichererweiterung um 200GB für € 39,90' }];
  @Prop({ default: () => userBillingInfo.parse({}) })
  private value!: UserBillingInfo;
  @Watch('value')
  onUpdateValue(newV: UserBillingInfo, oldV: UserBillingInfo) {
    this.model = Object.assign(this.model, newV);
  }
  private model: UserBillingInfo = userBillingInfo.parse({});

  resetValidation() {
    if (this.refUserStorageSpaceInfoForm) this.refUserStorageSpaceInfoForm.resetValidation();
  }
  get rules() {
    return {
      required: (value: any) => !!value || this.$t('required'),
      iban: (value: string) => IBANUtils.isValid(value) || this.$t('iban_invalid'),
    };
  }
  async onClickedUpdate() {
    const isFormValidResult = await this.refUserStorageSpaceInfoForm.validate();
    if (isFormValidResult) {
      this.model.addonHddPackangeId = this.addonHddPackange.id;
      this.$emit('click:update', this.model);
    }
  }

  onClickedClose() {
    this.$emit('click:close');
  }
}
