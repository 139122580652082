import { Component, Prop, Vue, Watch } from 'vue-property-decorator';
import { Logger } from 'fsts';
import { namespace } from 'vuex-class';
import userBillingInfo, { UserBillingInfo } from '@/shared/model/userBillingInfo';

const name = 'user-billing-info-dialog';
const logger = new Logger(name);
const userBillingInfoModule = namespace('userBillingInfo');

@Component({ name: name })
export default class UserBillingInfoDialog extends Vue {
  @Prop({ default: false })
  private dialog!: boolean;

  @Prop({ default: () => userBillingInfo.parse({}) })
  private value!: UserBillingInfo;
  @Watch('value')
  onUpdateValue(newV: UserBillingInfo, oldV: UserBillingInfo) {
    this.model = Object.assign(this.model, newV);
  }
  private model: UserBillingInfo = userBillingInfo.parse({});

  onClickedUpdate() {
    this.$emit('click:update', this.model);
  }

  onClickedClose() {
    this.$emit('click:close');
  }
}
