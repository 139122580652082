import { Component, Prop, Vue, Watch } from 'vue-property-decorator';
import { Logger } from 'fsts';
import { namespace } from 'vuex-class';
import userBillingInfo, { UserBillingInfo } from '@/shared/model/userBillingInfo';
import UserSubscriptionsInfoDialog from './user-subscriptions-info-edit/user-subscriptions-info-edit.vue';
import { Organisation } from '@/shared/model/organisation';
import NumberUtils from '@/shared/utils/NumberUtils';

const name = 'user-subscriptions-info-view';
const logger = new Logger(name);
const userBillingInfoModule = namespace('userBillingInfo');
const organizationModule = namespace('organization');
const authModule = namespace('auth');
const folderModule = namespace('folder');
const documentModule = namespace('document');

@Component({ name: name, components: { UserSubscriptionsInfoDialog } })
export default class UserSubscriptionsInfoView extends Vue {
  @folderModule.Action('getMenuFolders')
  private getFolders!: () => {};
  @userBillingInfoModule.Getter('getUserBillingInfo')
  private userBillingInfo!: UserBillingInfo;
  @userBillingInfoModule.Action('getUserBillingInfo')
  private actionGetUserBillingInfo!: any;
  @userBillingInfoModule.Action('updateUserBillingInfo')
  private actionUpdateUserBillingInfo!: any;
  @userBillingInfoModule.Getter('getInProgressUpdateUserBillingInfo')
  private inProgressUpdateUserBillingInfo!: any;
  @organizationModule.Getter('getOrganization')
  private organisation!: Organisation;
  @organizationModule.Action('getOrganization')
  private getOrganisation!: any;
  @organizationModule.Action('getOrganizations')
  private getOrganisations!: any;
  @organizationModule.Action('deleteOrganization')
  private deleteOrganization!: any;
  @organizationModule.Action('updateToMain')
  private updateToMain!: any;
  @organizationModule.Getter('getInProgressUpdateToMain')
  private inProgressUpdateToMain!: any;
  @authModule.Action('signinSilent')
  private signinSilent!: any;
  @documentModule.Action('getDocumentFoldersCounts')
  private getDocumentFoldersCounts!: any;

  userBillingInfoEditDialog = {
    show: false,
    model: { ...userBillingInfo.parse({}) },
  };
  async userBillingInfoEditDialogOnClose(userBillingInfo: UserBillingInfo) {
    this.userBillingInfoEditDialog.show = false;
  }
  async userBillingInfoEditDialogOnUpdate(ubi: UserBillingInfo) {
    if (this.organisation.isDemo) {
      await this.updateToMain({ organisationId: this.organisation.id, userBillingInfo: ubi });
    } else {
      ubi.organisationId = this.organisation.id;
      await this.actionUpdateUserBillingInfo(ubi).then(async ({ id }: any) => {});
    }
    this.userBillingInfoEditDialog.show = false;
    await Promise.all([
      this.actionGetUserBillingInfo(),
      this.getOrganisation(this.organisation.id),
      this.getOrganisations(),
      this.getFolders(),
      this.getDocumentFoldersCounts(),
    ]);
    await this.signinSilent();
  }
  onClickAdd() {
    //reset init newOrganization name with pass billinginfo
    this.userBillingInfoEditDialog.model = Object.assign({ newOrganizationName: '' }, { ...this.userBillingInfo });
    this.userBillingInfoEditDialog.show = true;
  }
  get addonHddPackageCount() {
    return this.userBillingInfo.addonHddPackageCount ?? 0;
  }
  get addonHddPackagesPrice() {
    return NumberUtils.twoAfterDot(this.addonHddPackageCount * this.userBillingInfo.addonHddPackagePrice);
  }
  get addonPackageCount() {
    return this.userBillingInfo.addonPackageCount ?? 0;
  }
  get addonPackagesPrice() {
    return NumberUtils.twoAfterDot(this.addonPackageCount * this.userBillingInfo.addonPackagePrice);
  }
  get totalPrice() {
    return this.addZeroes(
      NumberUtils.twoAfterDot(
        this.userBillingInfo.mainPackagePrice + this.addonHddPackagesPrice + this.addonPackagesPrice
      )
    );
  }
  get mainPackagePrice(): string {
    return this.addZeroes(this.userBillingInfo.mainPackagePrice);
  }

  addZeroes(str: any) {
    return NumberUtils.addZeroes('' + str, this.$root.$i18n.locale.toLocaleLowerCase());
  }
}
