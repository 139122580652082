import { render, staticRenderFns } from "./user-storage-space-info.html?vue&type=template&id=cd7d2906&scoped=true&external"
import script from "./user-storage-space-info.ts?vue&type=script&lang=ts&external"
export * from "./user-storage-space-info.ts?vue&type=script&lang=ts&external"
import style0 from "./user-storage-space-info.scss?vue&type=style&index=0&id=cd7d2906&prod&scoped=true&lang=scss&external"


/* normalize component */
import normalizer from "!../../../../../node_modules/@vue/cli-service/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "cd7d2906",
  null
  
)

/* custom blocks */
import block0 from "./user-storage-space-info.json?vue&type=custom&index=0&blockType=i18n&issuerPath=%2Fusr%2Fsrc%2Fapp%2Fsrc%2Fviews%2Fsettings%2Fsubscriptions%2Fuser-storage-space-info%2Fuser-storage-space-info.vue&external"
if (typeof block0 === 'function') block0(component)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VForm } from 'vuetify/lib/components/VForm';
import { VProgressLinear } from 'vuetify/lib/components/VProgressLinear';
import { VRow } from 'vuetify/lib/components/VGrid';
installComponents(component, {VBtn,VCol,VForm,VProgressLinear,VRow})
